<template>
  <div v-if="editor" class="editor" :class="{ disabled: fadeOnDisabled && disabled, 'fixed-height': fixedHeight }">
    <editor-panel
      v-if="!disabled"
      :excluded-functions="excludedFunctions"
      :floating="floatingPanel"
      :editor="editor"
      :size="size"
      :enabled-optional-plugins="enabledOptionalPlugins"
    />
    <StyledContent :light="light" style="overflow-y: scroll;">
      <editor-content :editor="editor" class="content" />
    </StyledContent>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Panel from './Panel';
import StyledContent from './StyledContent';
import Video from './EmbeddedVideo';

export default {
  components: {
    EditorContent,
    'editor-panel': Panel,
    StyledContent
  },
  props: {
    modelValue: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'md'
    },
    light: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fadeOnDisabled: {
      type: Boolean,
      default: true
    },
    floatingPanel: {
      type: Boolean
    },
    fixedHeight: {
      type: Boolean,
      default: true
    },
    excludedFunctions: {
      type: Array,
      default: () => []
    },
    enabledOptionalPlugins: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:modelValue', 'blur', 'focus'],

  data() {
    return {
      editor: null
    };
  },
  watch: {
    modelValue(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
    disabled(nw) {
      this.editor.setEditable(!nw);
    }
  },
  mounted() {
    const editable = !this.disabled;
    this.editor = new Editor({
      content: this.modelValue,
      editable,
      extensions: [
        StarterKit,
        Video,
        Link.configure({
          openOnClick: false
        })
      ],
      onUpdate: () => {
        this.$emit('update:modelValue', this.editor.getHTML());
      },
      onFocus: () => {
        this.$emit('focus');
      },
      onBlur: ({ event }) => {
        if (event.relatedTarget instanceof HTMLElement && Array.from(event.relatedTarget.classList).includes('control-button')) {
          return;
        }
        this.$emit('blur');
      },
      onCreate() {
        this.commands.focus('start');
      }
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  }
};
</script>
<style lang="scss">
.editor {
  width: 100%;
}
.fixed-height {
  .content {
    div[contenteditable] {
      min-height: 150px;
      // height: 200px;
    }
  }
}
</style>
